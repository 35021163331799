body {
   margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top-left {
  top: 0;
  left: 0;
}

/* table {
  border: 2px solid blue;
  width: 800px;
  height: 300px;
  margin-left: 280px;
}

tbody {
  margin: 20px;
}

th {
  border-bottom: 1px solid black;
  text-align: left;
}

tr td {
  text-align: left;
  margin: 5px;
} */


table {
  width: 800px;
  padding: 1em;
  margin: 20px auto;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
  /* margin-left: 280px; */
}

thead {
  background-color: #caf0ec;
  font-size: 16px;
}

th {
  text-align: left;
  padding: 8px;
  background-color: rgb(0, 128, 255);
  color: black;
}
td {
  text-align: left;
  padding: 8px;
}

tr {
  height: 50px;
}

tr:nth-child(even) {
  background-color: #e0e0e0;
}





.navbar {
  background-color: #222;
  color: white;
  padding: 10px;
  text-align: center;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
  cursor: pointer;
}

.sidebar li:hover {
  color: lightblue;
}

.categories-container {
  display: grid;
  gap: 10px;
  margin:0 20px;
  width: "50px";
  border: 1px solid black;

}


.category-row {
  display: flex;
  /* align-items: center; */
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* justify-content: space-between; */
}

 .category-name {
  display: flex;
  align-items: center;
} 

.toggle-button {
  display: flex;
  align-items: center;
}

.category-children {
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-left: 20px;
}

.subcategory {
  display: flex;
  align-items: center;
}

.category-header{
  display: flex;
  justify-content: space-between;
}

.checkbox-column,
.id-column,
.name-column,
.subcategory-column {
  border: 1px solid #ccc;
  padding: 8px;
  margin: 4px; 
}

.category-header {
  font-weight: bold;
}

.button {
  border: none;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.button:hover {
  cursor: pointer;
}

.category-subcategory.subcategory-column{
display: none;  
}

.category-header{width:100%;}

.category-row > div{width:100%;}

.category-row .toggle-button{
justify-content: center;
}

.category-row .toggle-button button{
  background-color: #0080ff;
  border: none;
  padding: 7px 10px;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.category-row .toggle-button button[disabled],
button[disabled],
.pagination-last{
opacity:0.5;
cursor:not-allowed;  
}

.btn,
.pagination > li > button{
    background-color: #0080ff;
    border: none;
    padding: 7px 10px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  list-style: none;
  column-gap: 6px;
  cursor: pointer;
}

.popup-buttons{
margin: 20px 0;
}

.popup-buttons .btn{
margin:0 4px;  
}

.App > div:first-child a{display: none;}

.App > div:first-child div:nth-child(2){flex-grow:1;}

.App nav + div{margin-left:250px;}

.sidebar-header{
  display:flex;
  flex-direction:column;
  color:white;
  justify-content:space-between;
  margin-left: 250px;
}

.jayostu_img{
  width: "100px";
  height: "80px";
  padding-top:"10px"
}

.user_search{
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.user_search > div{
display: inline-block;
margin-left: 6px;
}

.user_search input[type="text"]{
  padding: 8px 10px;
}

.sidebar_content {width:100%;}

.sidebar_content > div a:nth-child(1){
justify-content: center;
}

.sidebar_content > div a {
max-width: 125px;
margin: 0 auto;
transition: 0.4s;
}

.sidebar_content > div a:hover{
max-width:100%;
justify-content: center;
transition: 0.4s;
}

.pagination a {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid  #0080ff;
  color:  #0080ff;
}

/* .pagination__link {
  font-weight: bold;
} */

.pagination__link--active a {
  color: #fff;
  background: #0080ff;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
